<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="商品名称" prop="searchValue">
              <el-input
                clearable
                placeholder="请输入商品名称"
                v-model="form.name"
                :maxlength="8"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="6" class="text-right">
            <el-button type="primary" @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button type="primary" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <div class="table-container-col">
        <el-table
          :data="tableData"
          :height="tableHeight - 20"
          style="width: 95%"
          border
          @sort-change="sort_post_change"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="code" align="center" label="编号" min-width="80"> </el-table-column>
          <el-table-column prop="name" align="left" min-width="150" label="商品名称">
          </el-table-column>
          <el-table-column
            prop="unitText"
            align="center"
            header-align="center"
            min-width="100"
            label="单位"
          >
          </el-table-column>
          <el-table-column
            prop="stockQty"
            align="center"
            min-width="120"
            show-overflow-tooltip
            label="库存数量"
          >
          </el-table-column>
          <el-table-column
            prop="sellPrice"
            align="center"
            min-width="120"
            show-overflow-tooltip
            label="价格（元）"
          >
          </el-table-column>
          <el-table-column
            align="center"
            width="120px"
            fixed="right"
            :label="$t('option')">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-repeatClick
                :disabled="selectIdArr.includes(scope.row.id) || scope.row.stockQty <= 0"
                round
                type="primary"
                @click="addGoods(scope.row)">购买</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageination-wrapper">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="table-container-col">
        <el-table
          :data="goodsTable"
          :height="tableHeight - 20"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="code" align="center" label="编号" min-width="80"> </el-table-column>
          <el-table-column prop="name" align="left" min-width="150" label="商品名称">
          </el-table-column>
          <el-table-column
            prop="unitText"
            align="center"
            header-align="center"
            min-width="100"
            label="单位"
          >
          </el-table-column>
          <el-table-column
            align="center"
            min-width="200"
            show-overflow-tooltip
            label="数量"
          >
            <template slot-scope="scope">
              <el-input-number :step="1" step-strictly size="mini" v-model="scope.row.num" @input="numChange($event, scope.$index)" controls-position="right" :min="0" :max="scope.row.stockQty"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="sellPrice"
            align="center"
            min-width="120"
            show-overflow-tooltip
            label="价格（元）"
          >
          </el-table-column>
          <el-table-column
            prop="totalPrice"
            align="center"
            min-width="180"
            show-overflow-tooltip
            label="应付价格（元）"
          >
          </el-table-column>
          <el-table-column
            align="center"
            width="120px"
            fixed="right"
            :label="$t('option')">
            <template slot-scope="scope">
              <el-button size="mini" v-repeatClick round type="primary" @click="removeGoods(scope.$index)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px;">
          <el-button
            class="default-button"
            :disabled="goodsTable.length < 1"
            @click="changeAddDialog(true)"
            v-if="buttonAuth.includes('goodsSale:buy')"
            >提交</el-button
          >
          <el-button
            class="default-button"
            @click="clearTable"
            >清空</el-button
          >
        </div>
      </div>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :totalPrice="totalPrice"
      :goodsData="goodsTable"
      @closeDialog="changeAddDialog"
      @clearTable="clearTable"
    ></add-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import IndexClass from "./indexClass.js";
import apis from "@/apis";
import addDialog from "./dialog/addDialog.vue";
import { changeInfo } from "@/utils/index";

export default {
  mixins: [mixin],
  components: {
    addDialog,
  },
  data() {
    return {
      form: new IndexClass("form"),
      tableData: [],
      goodsTable: [],
      addDialog: {
        visible: false,
      },
      totalPrice: 0,
      selectIdArr: [],
    };
  },
  computed: {
    ...mapState(["Language", "venueId", "DROPDOWNBOX"]),
  },
  methods: {
    // 清空
    clearTable() {
      this.goodsTable = []
      this.selectIdArr = []
    },
    // 购买
    addGoods(goods) {
      this.goodsTable.push(goods)
      this.selectIdArr.push(goods.id)
    },
    // 移除
    removeGoods(index) {
      this.goodsTable.splice(index, 1)
      this.selectIdArr.splice(index, 1)
    },
    // 计算合计金额
    numChange(v, i) {
      if (v) {
        this.goodsTable[i].totalPrice = (v * this.goodsTable[i].sellPrice).toFixed(2)
      } else {
        this.goodsTable[i].totalPrice = '-'
      }
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeAddDialog(dialogStatus) {
      if (dialogStatus) {
        const index = this.goodsTable.findIndex(item => item.num < 1)
        console.log(index)
        if (index > -1) {
          this.$message.warning('购买数量不能小于1')
          return
        }
        let sum = 0
        this.goodsTable.forEach((item) => {
          sum += Number(item.totalPrice)
        })
        this.totalPrice = sum.toFixed(2)
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.venueProductStockQuery, { ...this.form, storeId: this.venueId, state: '1' }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows && res.data.rows.map((item) => {
            item.num = 0
            item.unitText = changeInfo(item.unit, this.DROPDOWNBOX.PRODUCT_UNIT, "value", "label");
            return item;
          });
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.table-container {
  display: flex;
  justify-content: space-between;
  &-col {
    width: 50%
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
