/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2021-11-30 10:01:11
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.payType = ''
    } else if (type === 'rule') {
      this.payType = [
        {
          required: true,
          message: '商品名称不能为空',
          trigger: ['blur', 'change'],
        },
      ]
    }
  }
}
export default searchFrom
