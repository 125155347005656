<template>
  <div class="dialog-container">
    <el-dialog
      title="付款"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close="clearTable"
      center
      :close-on-click-modal="false"
      width="800px"
    >
      <el-row v-for="(item, index) in goodsData" :key="index">
        <el-col :span="10" class="form-item">
          <span class="form-item-required">*</span>
          <span class="form-item-text">商品名称</span>
          <el-input
            class="form-item-input"
            v-model="item.name"
            :disabled="true">
          </el-input>
        </el-col>
        <el-col :span="7" class="form-item">
          <span class="form-item-required">*</span>
          <span class="form-item-text">数量</span>
          <el-input
            class="form-item-input"
            v-model="item.num"
            :disabled="true">
          </el-input>
        </el-col>
        <el-col :span="7" class="form-item">
          <span class="form-item-required">*</span>
          <span class="form-item-text">价格（元）</span>
          <el-input
            class="form-item-input"
            v-model="item.totalPrice"
            :disabled="true">
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" class="form-item">
          <span class="form-item-required">*</span>
          <span class="form-item-text">合计</span>
          <el-input
            class="form-item-input"
            v-model="totalPrice"
            :disabled="true">
          </el-input>
        </el-col>
      </el-row>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="10">
            <el-form-item label="付款方式" prop="payType">
             <el-select v-model="form.payType" style="width: 100%">
                <el-option v-for="item in payTypeList" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent" v-repeatClick>{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
      <el-dialog
        title="微信支付"
        :visible.sync="payDialog.show"
        center
        :close-on-click-modal="false"
        :append-to-body="true"
        width="400px">
        <div style="display:flex;width:100%">
            <img :src="payDialog.paymentQRCode" alt="" style="margin: 0 auto;">
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    goodsData: {
      required: true,
      type: Array,
    },
    totalPrice: {
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      ImageUpload: apis.ImageUpload,
      showFlag: true,
      payDialog: {
        show: false,
        paymentQRCode: '',
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueId"]),
    payTypeList() {
      return [{
        label: '线下-现金',
        value: '2',
        disabled: false,
      }, {
        label: '线下-微信',
        value: '5',
        disabled: this.totalPrice <= 0,
      }]
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
    },
    clearTable() {
      console.log(111)
      this.$emit('clearTable')
      this.closeDialog()
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.flag)
          if (this.flag) {
            return;
          }
          this.flag = true;
          const venueProductCheckDTOList = this.goodsData.map((item) => {
            return {
              number: item.num,
              productId: item.id,
              venueId: this.venueId,
            }
          })
          this.$http.post(apis.venueProductSell, {
            ...this.form,
            venueProductCheckDTOList,
            }).then((res) => {
            if (res.data.code === 0) {
              if (this.form.payType === '5') {
                this.payDialog.show = true
                this.payDialog.paymentQRCode = res.data.rows.paymentQRCode
                const wsServer = window.location.protocol === 'http:' ? `ws://${window.location.host}/payapi/websocket/${res.data.rows.orderNo}` : `wss://bjat.aofeijituan.com.cn/payapi/websocket/${res.data.rows.orderNo}`
                const websocket = new WebSocket(wsServer) // 创建WebSocket对象
                console.log(websocket.readyState) // 查看websocket当前状态
                websocket.onmessage = (evt) => {
                    console.log('推送数据', evt)
                    const data1 = JSON.parse(evt.data)
                    if (data1.type === 'pcreservate') {
                        if (data1.code === '0') {
                            this.$message.success('操作成功')
                            this.closePayDialog()
                            this.$emit('close')
                            this.closeDialog()
                        }
                    }
                }
              } else {
                this.$message.success('购买成功！')
                this.clearTable()
              }
              this.flag = false;
            } else {
              this.flag = false;
            }
          }).catch(() => {
            this.flag = false
          });
        }
      });
    },
    // 关闭微信支付弹窗
    closePayDialog() {
        this.payDialog.show = false
    },
  },
};
</script>
<style lang="less" scoped>
.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &-required {
    color: #F56C6C;
    margin-right: 4px;
    margin-left: 20px;
  }
  &-text {
    font-size: 14px;
    color: #151515;
    padding-right: 12px;
    white-space: nowrap;
  }
  &-input {
    flex: 1
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
